<template>
  <b-sidebar
    id="add-new-package-sidebar"
    :visible="isEditPackageSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-edit-package-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Edit Package</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group :label="$t('Form.Name')" label-for="name">
              <b-form-input
                id="name"
                v-model="stateData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Package name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Description -->
          <validation-provider
            #default="validationContext"
            name="description"
            rules="required"
          >
            <b-form-group
              :label="$t('Form.Description')"
              label-for="description"
            >
              <b-form-input
                id="description"
                v-model="stateData.description"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Package description"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
				
				<!-- Clinical package category -->
          <validation-provider
            #default="validationContext"
            name="clinical_package_category_id"
            rules=""
          >
            <b-form-group label="Package category" label-for="clinical_package_category_id">
              <v-select
                id="clinical_package_category_id"
                v-model="stateData.clinical_package_category_id"
                :state="getValidationState(validationContext)"
                :options="clinicalPackageCategoryOptions"
                :reduce="(value) => value.id"
                label="name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Price -->
          <validation-provider
            #default="validationContext"
            name="Price"
            rules="required"
          >
            <b-form-group label="Price" label-for="price">
              <b-form-input
                id="price"
                v-model="stateData.price"
                autofocus
                :state="getValidationState(validationContext)"
                type="number"
                placeholder="Package price"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Regular price -->
          <validation-provider
            #default="validationContext"
            name="Regular price"
            rules="required|min_value:0|decimal:2"
          >
            <b-form-group label="Regular price" label-for="regular_price">
              <b-form-input
                id="regular_price"
                v-model="stateData.regular_price"
                :state="getValidationState(validationContext)"
                type="number"
                placeholder="Regular price"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Discount -->
          <validation-provider
            #default="validationContext"
            name="Discount"
            rules="required"
          >
            <b-form-group label="Discount" label-for="discount">
              <b-form-input
                id="discount"
                v-model="stateData.discount"
                autofocus
                :state="getValidationState(validationContext)"
                placeholder="Discount"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- consult price -->
          <validation-provider
            #default="validationContext"
            name="consult_price_id"
            rules=""
          >
            <b-form-group label="Consul price" label-for="consult_price_id">
              <v-select
                id="consult_price_id"
                v-model="stateData.consult_price_id"
                :state="getValidationState(validationContext)"
                :options="consultPriceOptions"
                :reduce="(value) => value.id"
                label="name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- laboratories -->
          <validation-provider
            #default="validationContext"
            name="Laboratories"
            rules=""
          >
            <b-form-group label="Laboratories" label-for="laboratories">
              <v-select
                id="laboratories"
                v-model="stateData.laboratories"
                :state="getValidationState(validationContext)"
                :options="laboratoriesOptions"
                multiple
                :reduce="(val) => val.id"
                label="name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- laboratories limit -->
          <validation-provider
            #default="validationContext"
            name="Laboratories_Limit"
            rules="required"
          >
            <b-form-group
              label="Limit Laboratories"
              label-for="laboratories_limit"
            >
              <b-form-input
                id="laboratories_limit"
                v-model="stateData.limits.laboratories"
                :state="getValidationState(validationContext)"
                type="number"
                placeholder="0"
              />
              <span class="text-danger">
                {{
                  showError && limitsError.laboratories.value
                    ? "Limit must not be lower than the selected items"
                    : null
                }}
              </span>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- laboratories packs -->
          <validation-provider
            #default="validationContext"
            name="Laboratory Packs"
            rules=""
          >
            <b-form-group label="Laboratory Packs" label-for="laboratories">
              <v-select
                id="laboratories"
                v-model="stateData.laboratories_pack"
                :state="getValidationState(validationContext)"
                :options="laboratoriesPackOptions"
                :reduce="(val) => val.id"
                multiple
                label="name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- laboratories pack limit -->
          <validation-provider
            #default="validationContext"
            name="Laboratories_pack_Limit"
            rules="required"
          >
            <b-form-group
              label="Limit Laboratories Packs"
              label-for="laboratories_pack_limit"
            >
              <b-form-input
                id="laboratories_pack_limit"
                v-model="stateData.limits.laboratories_pack"
                :state="getValidationState(validationContext)"
                type="number"
                placeholder="0"
              />
              <span class="text-danger">
                {{
                  showError && limitsError.laboratories_pack.value
                    ? "Limit must not be lower than the selected items"
                    : null
                }}
              </span>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- tests -->
          <validation-provider
            #default="validationContext"
            name="Tests"
            rules=""
          >
            <b-form-group label="Tests" label-for="test">
              <v-select
                id="test"
                v-model="stateData.tests"
                :state="getValidationState(validationContext)"
                :options="testOptions"
                multiple
                :reduce="(val) => val.id"
                label="name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- tests limit -->
          <validation-provider
            #default="validationContext"
            name="tests_Limit"
            rules="required"
          >
            <b-form-group label="Limit Tests" label-for="tests_limit">
              <b-form-input
                id="tests_limit"
                v-model="stateData.limits.tests"
                :state="getValidationState(validationContext)"
                type="number"
                placeholder="0"
              />
              <span class="text-danger">
                {{
                  showError && limitsError.tests.value
                    ? "Limit must not be lower than the selected items"
                    : null
                }}
              </span>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- procedures -->
          <validation-provider
            #default="validationContext"
            name="Procedures"
            rules=""
          >
            <b-form-group label="Procedures" label-for="procedure">
              <v-select
                id="procedure"
                v-model="stateData.procedures"
                :state="getValidationState(validationContext)"
                :options="procedureOptions"
                multiple
                :reduce="(val) => val.id"
                label="name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- procedures limit -->
          <validation-provider
            #default="validationContext"
            name="procedures_limit"
            rules="required"
          >
            <b-form-group label="Limit Procedures" label-for="procedures_limit">
              <b-form-input
                id="procedures_limit"
                v-model="stateData.limits.procedures"
                :state="getValidationState(validationContext)"
                type="number"
                placeholder="0"
              />
              <span class="text-danger">
                {{
                  showError && limitsError.procedures.value
                    ? "Limit must not be lower than the selected items"
                    : null
                }}
              </span>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- diagnostic imagings -->
          <validation-provider
            #default="validationContext"
            name="Diagnostic Imagings"
            rules=""
          >
            <b-form-group label="Diagnostic Imagings" label-for="diagnostic">
              <v-select
                id="diagnostic"
                v-model="stateData.diagnostic_imagings"
                :state="getValidationState(validationContext)"
                :options="diagnosticImagingOptions"
                multiple
                :reduce="(val) => val.id"
                label="name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- diagnostic imagings limit -->
          <validation-provider
            #default="validationContext"
            name="diagnostic_imagings_limit"
            rules="required"
          >
            <b-form-group
              label="Limit Diagnostic Imagings"
              label-for="diagnostic_imagings_limit"
            >
              <b-form-input
                id="diagnostic_imagings_limit"
                v-model="stateData.limits.diagnostic_imagings"
                :state="getValidationState(validationContext)"
                type="number"
                placeholder="0"
              />
              <span class="text-danger">
                {{
                  showError && limitsError.diagnostic_imagings.value
                    ? "Limit must not be lower than the selected items"
                    : null
                }}
              </span>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Treatment -->
          <validation-provider
            #default="validationContext"
            name="Treatments"
            rules=""
          >
            <b-form-group label="Treatments" label-for="treatment">
              <v-select
                id="treatment"
                v-model="treatmentsSelected"
                :state="getValidationState(validationContext)"
                :options="
                  treatmentsOptions.filter(
                    (o) => treatmentsSelected.indexOf(o) < 0
                  )
                "
                multiple
                label="name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Treatment limit -->
          <validation-provider
            #default="validationContext"
            name="treatmets_limit"
            rules="required"
          >
            <b-form-group label="Limit Treatments" label-for="treatmets_limit">
              <b-form-input
                id="treatmets_limit"
                v-model="stateData.limits.treatments"
                :state="getValidationState(validationContext)"
                type="number"
                placeholder="0"
              />
              <span class="text-danger">
                {{
                  showError && limitsError.treatments.value
                    ? "Limit must not be lower than the selected items"
                    : null
                }}
              </span>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div
            v-for="(item, index) in stateData.treatments"
            :key="index"
            class="d-flex"
          >
            <input
              type="text"
              class="form-control"
              :value="item.name"
              disabled
            />
            <input
              type="number"
              class="form-control"
              v-model="item.cant"
              max="10"
              min="1"
            />
          </div>

          <!-- status -->
          <validation-provider
            #default="validationContext"
            name="Status"
            rules="required"
          >
            <b-form-group :label="$t('Form.Status')" label-for="status">
              <v-select
                id="status"
                v-model="stateData.status"
                :state="getValidationState(validationContext)"
                :options="optionsStatus"
                :reduce="(val) => val.value"
                :clearable="false"
                label="text"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="loading"
            >
              <span v-if="!loading">Edit</span>
              <span v-else>
                <SpinnerLoading />
              </span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-danger"
              @click="hide"
            >
              {{ $t("Cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SpinnerLoading from "@/components/SpinnerLoading";

import vSelect from "vue-select";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import { onMounted, ref, watch } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";

import axiosPC from "@/services/admin/ProcedurePackages.js";
import axiosD from "@/services/admin/consults/consultPrices";
import axiosL from "@/services/admin/consults/laboratories";
import axiosT from "@/services/admin/consults/tests";
import axiosP from "@/services/admin/consults/procedures";
import axiosDI from "@/services/admin/consults/diagnosticImages";
import axiosTR from "@/services/admin/consults/treatments";
import axiosCP from "@/services/admin/consults/consultPrices";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    SpinnerLoading,

    vSelect,
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditPackageSidebarActive",
    event: "update:is-edit-package-sidebar-active",
  },
  props: {
    isEditPackageSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      required,
      config: {
        dateFormat: "m-d-Y",
        allowInput: true,
      },
    };
  },
  setup(props, { emit }) {
    const optionsStatus = [
      { text: "Active", value: true },
      { text: "Inactive", value: false },
    ];

    const treatmentsSelected = ref([]);
    watch(treatmentsSelected, (newValue, oldValue) => {
      if (oldValue.length == 0 && stateData.value.treatments.length > 0) return;

      if (newValue.length > oldValue.length) {
        const selected = newValue[newValue.length - 1];
        stateData.value.treatments.push({
          id: selected.id,
          name: selected.name,
          price: selected.price,
          cant: 1,
        });
      } else {
        const treatmentEliminated = oldValue.findIndex(
          (elemento) => newValue.indexOf(elemento) == -1
        );
        stateData.value.treatments.splice(treatmentEliminated, 1);
      }
    });

    onMounted(() => {
      getDataItems();
      getData();
    });

    const stateDataTem = {
      name: "",
      description: "",
      default: false,
      price: null,
      regular_price: null,
      discount: null,
      laboratories: [],
      laboratories_pack: [],
      tests: [],
      procedures: [],
      diagnostic_imagings: [],
      treatments: [],
      limits: {
        laboratories: 0,
        laboratories_pack: 0,
        tests: 0,
        procedures: 0,
        diagnostic_imagings: 0,
        treatments: 0,
      },
      status: "",
    };
    const stateData = ref(JSON.parse(JSON.stringify(stateDataTem)));

    // const stateData = ref({});
    const showForm = ref(false);

    const getData = () => {
      const { id } = props.itemEdit.value;
      axiosPC.packageView(id).then(({ registro }) => {
        const treatmentsDefault = registro.treatments.map((res) => {
          return {
            id: res.id,
            name: res.name,
            price: res.price,
            cant: res.pivot.cant,
            pivot: {
              limit: res.pivot.limit,
            },
          };
        });

        stateData.value = {
          status: registro.status,
          name: registro.name,
          description: registro.description,
          price: registro.price,
          discount: registro.discount,
          regular_price: registro.regular_price,
          consult_price_id: registro.consult_price_id,
          laboratories: extractIds(registro.laboratories),
          laboratories_pack: extractIds(registro.laboratories_pack),
          tests: extractIds(registro.tests),
          procedures: extractIds(registro.procedures),
          diagnostic_imagings: extractIds(registro.diagnostic_imagings),
          treatments: treatmentsDefault,
					clinical_package_category_id:registro.clinical_package_category_id,
          limits: {
            laboratories: extractLimits(registro.laboratories),
            laboratories_pack: extractLimits(registro.laboratories_pack),
            tests: extractLimits(registro.tests),
            procedures: extractLimits(registro.procedures),
            diagnostic_imagings: extractLimits(registro.diagnostic_imagings),
            treatments: extractLimits(treatmentsDefault),
          },
        };

        treatmentsSelected.value = treatmentsDefault.map((treatment) => {
          return {
            id: treatment.id,
            name: treatment.name,
            price: treatment.price,
          };
        });

        showForm.value = true;
      });
    };

    const extractIds = (list) => list.map((item) => item.id);

    const extractLimits = (data) => {
      let limit = 0;
      data.forEach((item) => {
        limit = item.pivot.limit;
      });
      return limit;
    };

    const laboratoriesOptions = ref([]);
    const laboratoriesPackOptions = ref([]);
    const testOptions = ref([]);
    const procedureOptions = ref([]);
    const diagnosticImagingOptions = ref([]);
    const treatmentsOptions = ref([]);
    const consultPriceOptions = ref([]);
    const clinicalPackageCategoryOptions = ref([]);

    const limitsError = {
      laboratories: ref(false),
      laboratories_pack: ref(false),
      tests: ref(false),
      procedures: ref(false),
      diagnostic_imagings: ref(false),
      treatments: ref(false),
    };

    const getDataItems = () => {
      axiosL.laboratoryList(200).then(({ data }) => {
        laboratoriesOptions.value = data;
        data.forEach((item) => {
          if (item.categories[0].price) {
            if (
              !laboratoriesPackOptions.value.some(
                (itemFind) => itemFind.id === item.categories[0].id
              )
            ) {
              laboratoriesPackOptions.value.push(item.categories[0]);
            }
          }
        });
      });
      axiosT.testList(100).then(({ data }) => {
        testOptions.value = data;
      });
      axiosP.procedureList(100).then(({ data }) => {
        procedureOptions.value = data;
      });
      axiosDI.diagnosticImageList(100).then(({ data }) => {
        diagnosticImagingOptions.value = data;
      });
      axiosTR.treatmentList(100).then(({ data }) => {
        treatmentsOptions.value = data;
      });
      axiosCP.consultPriceList(100).then(({data}) => {
        const consult = data.map((item) => {
          return {
            ...item,
            name: `${item.name} - ${item.price}`,
          };
        });
        consultPriceOptions.value = consult;
      });
			axiosPC.categoryClinicalPackages(100).then((data) => {
        const category = data.registro;
        clinicalPackageCategoryOptions.value = category;
      });
    };

    const resetuserData = () => {
      stateData.value = JSON.parse(JSON.stringify(props.itemEdit.value));
    };

    const loading = ref(false);
    const showError = ref(false);

    const onSubmit = () => {
      const { id } = props.itemEdit.value;
      loading.value = true;

      Object.keys(limitsError).forEach((key) => {
        validateLimits(key);
      });

      const hasLimitErrors = Object.values(limitsError).some(
        (error) => error.value
      );

      if (hasLimitErrors) {
        loading.value = false;
        showError.value = true;
        return;
      }

      loading.value = true;
      const PackageItem = JSON.parse(JSON.stringify(stateData));
      const data = {
        ...PackageItem.value,
      };
      axiosPC
        .packageUpdate(id, data)
        .then(() => {
          loading.value = false;
          resetuserData();
          emit("editPackageSuccess", true);
        })
        .catch(() => {
          emit("editPackageSuccess", false);
          loading.value = false;
        });
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    const validateLimits = (key) => {
      limitsError[key].value =
        stateData.value.limits[key] > stateData.value[key].length;
    };

    return {
      stateData,
      loading,
      treatmentsSelected,
      optionsStatus,
      treatmentsOptions,
      laboratoriesOptions,
      laboratoriesPackOptions,
      consultPriceOptions,
			clinicalPackageCategoryOptions,
      testOptions,
      procedureOptions,
      diagnosticImagingOptions,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      extractIds,
      validateLimits,
      showError,
      limitsError,
      extractLimits,
      showForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
