<template>
  <b-modal
    id="modal-lg"
    v-model="conditionPackage"
    hide-footer
    hide-header
    centered
    size="lg"
    @hide="onHide"
  >
    <b-card no-body class="card-browser-states" v-if="dataPackage">
      <b-card-header>
        <div>
          <b-card-title>{{ dataPackage.name }}</b-card-title>
        </div>
      </b-card-header>

      <!-- body -->
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        rounded="lg"
      ></b-overlay>
      <b-card-body>
        <!-- general info -->
				<div class="d-flex align-items-end">
          <h4 class="font-weight-bold mr-1">Package Price: </h4>
          <h4 class="font-weight-bold">${{ dataPackage.price }}</h4>
        </div>
				
        <div class="d-flex align-items-end">
          <h5 class="font-weight-bold mr-1">Regular price: </h5>
          <h5 class="font-weight-bold">${{ this.getRegularPrice() }}</h5>
        </div>
        <div class="d-flex align-items-end">
          <h5 class="font-weight-bold mr-1">Discount: </h5>
          <h5 class="font-weight-bold">${{ this.getRegularPrice() - dataPackage.price }}</h5>
        </div>

        <div>
          <span class="font-weight-bold text-body-heading mr-1"
            >Description:</span
          >
          {{ dataPackage.description }}
        </div>
        <div class="row" v-if="officeVisit">
          <div class="col-md-7">
            <div>
              <span class="font-weight-bold text-body-heading mr-1">User:</span>
              {{ dataPackage.users.full_name }}
            </div>
          </div>
          <div class="col-md-5 text-right">
            <div>
              <span class="font-weight-bold text-body-heading mr-1"
                >Created at:</span
              >
              {{ formatDate(dataPackage.created_at) }}
            </div>
          </div>
        </div>

        <!-- laboratories -->
        <div class="col-md-12 mt-3">
          <span class="font-weight-bold text-body-heading mr-1"
            >Laboratories</span
          >
          <div
            v-for="(item, i) in dataPackage.laboratories"
            :key="i"
            class="browser-states"
          >
            <b-media no-body>
              <feather-icon class="mr-1 text-primary" icon="GitBranchIcon" />
              <h6 class="align-self-center my-auto">
                {{ item.name }}
              </h6>
            </b-media>
            <div class="d-flex align-items-center">
              <span class="font-weight-bold text-body-heading mr-1">
                {{ item.price }}
              </span>
            </div>
          </div>
        </div>

        <!-- laboratories_pack -->
        <div class="col-md-12 mt-3">
          <span class="font-weight-bold text-body-heading mr-1"
            >Laboratories pack</span
          >
          <div
            v-for="(item, i) in dataPackage.laboratories_pack"
            :key="i"
            class="browser-states"
          >
            <b-media no-body>
              <feather-icon class="mr-1 text-primary" icon="GitBranchIcon" />
              <h6 class="align-self-center my-auto">
                {{ item.name }}
              </h6>
            </b-media>
            <div class="d-flex align-items-center">
              <span class="font-weight-bold text-body-heading mr-1">
                {{ item.price }}
              </span>
            </div>
          </div>
        </div>

        <!-- procedures -->
        <div class="col-md-12 mt-3">
          <span class="font-weight-bold text-body-heading mr-1"
            >Procedures</span
          >
          <div
            v-for="(item, i) in dataPackage.procedures"
            :key="i"
            class="browser-states"
          >
            <b-media no-body>
              <feather-icon class="mr-1 text-primary" icon="GitBranchIcon" />
              <h6 class="align-self-center my-auto">
                {{ item.name }}
              </h6>
            </b-media>
            <div class="d-flex align-items-center">
              <span class="font-weight-bold text-body-heading mr-1">
                {{ item.price }}
              </span>
            </div>
          </div>
        </div>

        <!-- diagnostic_imagings -->
        <div class="col-md-12 mt-3">
          <span class="font-weight-bold text-body-heading mr-1"
            >Diagnostic imagings</span
          >
          <div
            v-for="(item, i) in dataPackage.diagnostic_imagings"
            :key="i"
            class="browser-states"
          >
            <b-media no-body>
              <feather-icon class="mr-1 text-primary" icon="GitBranchIcon" />
              <h6 class="align-self-center my-auto">
                {{ item.name }}
              </h6>
            </b-media>
            <div class="d-flex align-items-center">
              <span class="font-weight-bold text-body-heading mr-1">
                {{ item.price }}
              </span>
            </div>
          </div>
        </div>

        <!-- tests -->
        <div class="col-md-12 mt-3">
          <span class="font-weight-bold text-body-heading mr-1">Tests</span>
          <div
            v-for="(item, i) in dataPackage.tests"
            :key="i"
            class="browser-states"
          >
            <b-media no-body>
              <feather-icon class="mr-1 text-primary" icon="GitBranchIcon" />
              <h6 class="align-self-center my-auto">
                {{ item.name }}
              </h6>
            </b-media>
            <div class="d-flex align-items-center">
              <span class="font-weight-bold text-body-heading mr-1">
                {{ item.price }}
              </span>
            </div>
          </div>
        </div>

        <!-- treatments -->
        <div class="col-md-12 mt-3">
          <span class="font-weight-bold text-body-heading mr-1"
            >Treatments</span
          >
          <div
            v-for="(item, i) in dataPackage.treatments"
            :key="i"
            class="browser-states"
          >
            <b-media no-body>
              <feather-icon class="mr-1 text-primary" icon="GitBranchIcon" />
              <h6 class="align-self-center my-auto">
                {{ item.name }} (x{{ item.pivot.cant }})
              </h6>
            </b-media>
            <div class="d-flex align-items-center">
              <span class="font-weight-bold text-body-heading mr-1">
                {{ getTotalPrice(item.price, item.pivot.cant) }}
              </span>
            </div>
          </div>
        </div>
      </b-card-body>
      <!--/ body -->
    </b-card>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  BFormGroup,
  BAvatar,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BMedia,
  BImg,
  BOverlay,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import SpinnerLoading from "@/components/SpinnerLoading";
import NotificationMixin from "@/mixins/NotificationMixin";

import vSelect from "vue-select";
import moment from "moment";

import axiosP from "@/services/admin/consults/consultPrices";
import axiosPC from "@/services/admin/ProcedurePackages.js";
export default {
  components: {
    BButton,
    BModal,
    BFormGroup,
    BAvatar,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BImg,
    BOverlay,

    ValidationProvider,
    ValidationObserver,

    SpinnerLoading,
    vSelect,
  },
  data() {
    return {
      conditionPackage: true,
      dataPackage: null,
      dataPackageData: [],
      loading: false,
			// regular:getRegularPrice(),
			discount:0,
    };
  },
  props: {
    PackageById: {
      type: Number,
      required: true,
    },
    officeVisit: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [NotificationMixin],
  mounted() {
    this.getPackage();
  },
  methods: {
    onHide() {
      this.$emit("closeModalPackage", false);
    },
    getPackage() {
      this.loading = true;
      axiosPC.packageView(this.PackageById).then(({ registro }) => {
        this.dataPackage = registro;
        this.loading = false;
      });
    },
    formatDate(date) {
      return moment(date).calendar();
    },
    getTotalPrice(price, quantity) {
      return (price * Number(quantity)).toString();
    },
		getRegularPrice(){
			let regularPrice = this.dataPackage.consult_price.price;
			this.dataPackage.laboratories.forEach(lab => {
				regularPrice += lab.price;
			})
			this.dataPackage.procedures.forEach(procedure => {
				regularPrice += procedure.price;
			})
			this.dataPackage.tests.forEach(test => {
				regularPrice += test.price;
			})
			this.dataPackage.treatments.forEach(treatment => {
				let total = treatment.price * treatment.pivot.cant
				regularPrice += total;
			})
			return regularPrice;
		}
  },
};
</script>

<style lang="scss"></style>
